import { APIError, ChiefAPIError } from "@api/types/backendTypes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { useAuth } from "../useAuth";

export const CreateCooperationSchema = z.object({
  name: z.string({ required_error: "Name is required" }),
  influencer_id: z.string(),
  placement_type: z.string(),
  source: z.string().optional(),
  scheduled_for: z.string().datetime(),
  commission: z.string().optional(),
  currency: z.string(),
  labels: z.array(z.string()),
  variable_commission: z.string().optional(),
  variable_commission_type: z.string().optional(),
});

export type CreateCooperationInput = z.infer<typeof CreateCooperationSchema>;

const onCreateCooperation = async (
  input: CreateCooperationInput,
  token: string
) => {
  if (!token) {
    throw new Error(
      "Invalid token provided! Please provide a valid kytron token."
    );
  }
  try {
    CreateCooperationSchema.parse(input);
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_CHIEF_V4_BASE_URL}/cooperations/create`,
      {
        name: input.name,
        influencer_id: input.influencer_id,
        placement_type: input.placement_type,
        source: input.source,
        scheduled_for: input.scheduled_for,
        commission: input.commission ?? 0,
        currency: input.currency,
        variable_commission: input.variable_commission ?? 0,
        variable_commission_type: input.variable_commission_type,
        labels: input.labels?.map((el) => el.trim()).filter((el) => el !== ""),
        links: [],
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    return response.data;
  } catch (err: any) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const apiError = axiosError.response?.data as ChiefAPIError;
      const firstErr = apiError.detail[0];
      throw new Error(firstErr?.msg ?? axiosError.message);
    }
    throw new Error("Unkown error occurred!");
  }
};

export const useCreateCooperation = () => {
  const queryClient = useQueryClient();
  const { data } = useAuth();
  return useMutation({
    mutationFn: (input: CreateCooperationInput) =>
      onCreateCooperation(input, data?.token ?? ""),
    onSuccess: async (data, values) => {
      queryClient.invalidateQueries({ queryKey: ["cooperations"] });
      queryClient.invalidateQueries({ queryKey: ["influencers"] });
      queryClient.invalidateQueries({ queryKey: ["influencer"] });
      queryClient.invalidateQueries({ queryKey: ["cooperation"] });
    },
  });
};
