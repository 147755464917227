import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { EventListRequest } from "@api/types/backendTypes";
dayjs.extend(utc);
dayjs.extend(timezone);

export type ToEventsRequestPayloadProps = {
  startTime: string;
  endTime: string;
  csids: string[];
  interactions: Array<"purchase">;
  selectedTimezone: string;
  dailyBreakdown?: boolean;
  utcOffset?: number;
  isComparedRequest: boolean;
};

/**
 * Creates the request payload for retrieving events from tracify.
 *
 * @param {ToEventsRequestPayloadProps} props
 * @param {string[]} props.csids Ids of the store to include for retrieval (csids).
 * @param {string} props.startTime Starting timepoint for analysis range.
 * @param {string} props.endTime Final timepoint for analysis range.
 * @param {Array<"purchase">} props.interactions Type of events you want to fetch
 * @param {string} props.selectedTimezone Timezone to use
 * @param {boolean} props.dailyBreakdown If results should be broken down to daily
 * @return {EventListRequest} Request payload usable for requests.
 */
export function toEventsRequestPayload({
  startTime,
  endTime,
  csids,
  interactions,
  selectedTimezone,
  dailyBreakdown,
  isComparedRequest,
}: ToEventsRequestPayloadProps): EventListRequest {
  const start = dayjs(dayjs(startTime).startOf("day")).tz(
    selectedTimezone,
    true
  );
  const end = dayjs(dayjs(endTime).endOf("day")).tz(selectedTimezone, true);

  let utcOffset = undefined;
  if (dailyBreakdown) utcOffset = start.utcOffset() / 60;

  return {
    csids: csids,
    collect_end: end.utc().format("YYYY-MM-DD HH:mm:ss"),
    collect_start: start.utc().format("YYYY-MM-DD HH:mm:ss"),
    interactions: interactions,
    daily_breakdown: dailyBreakdown,
    utc_offset: utcOffset,
    isComparedRequest,
  };
}
