import React, { PropsWithChildren, ReactElement, useCallback } from "react";
import { Table } from "@tanstack/react-table";
import Pagination from "../pagination";
import Select from "@ui/select/select";

const rowsPerPageOptions = [25, 50, 100, 200];

export function TablePagination<T extends Record<string, unknown>>({
  table,
  disableRowsPerPage,
  tableRef,
}: PropsWithChildren<{
  table: Table<T>;
  disableRowsPerPage?: boolean;
  tableRef: React.MutableRefObject<HTMLDivElement | null>;
}>): ReactElement | null {
  const handleChangePage = useCallback(
    (newPage: number) => {
      table.setPageIndex(Math.ceil(newPage - 1));
      tableRef?.current?.scrollTo({ top: 0, left: 0 });
    },
    [table, tableRef]
  );
  const onChangeRowsPerPage = useCallback(
    (value: string | number) => {
      table.setPageSize(Number(value));
    },
    [table]
  );
  return (
    <div className="flex items-center">
      {!disableRowsPerPage && (
        <div className="flex items-center">
          <p className="text-sm mr-2">Rows per Page:</p>
          <Select
            value={table.getState().pagination.pageSize.toString()}
            onChange={(event) =>
              onChangeRowsPerPage(event.target.value?.toString())
            }
            options={rowsPerPageOptions.map((option) => ({
              label: option.toString(),
              value: option.toString(),
            }))}
          ></Select>
        </div>
      )}
      {table.getPageCount() > 1 ? (
        <>
          <div className="w-4" />
          <Pagination
            //   rowsPerPageOptions={rowsPerPageOptions}
            count={Math.ceil(table.getPageCount())}
            //   rowsPerPage={pageSize}
            page={table.getState().pagination.pageIndex + 1}
            //   onChangePage={handleChangePage}
            //   onChangeRowsPerPage={onChangeRowsPerPage}
            onChange={(value) => handleChangePage(value)}
          >
            <Pagination.Next disabled={!table.getCanNextPage()}>
              <p className="px-2">Next</p>
            </Pagination.Next>
            <Pagination.Previous disabled={!table.getCanPreviousPage()}>
              <p className="px-2">Previous</p>
            </Pagination.Previous>
          </Pagination>
        </>
      ) : null}
    </div>
  );
}
