import axios, { AxiosError } from "axios";
import {
  SiterefListFetchRequest,
  Siteref,
  SiterefListRequest,
  RequestSiterefCreateRequest,
  APIError,
} from "@api/types/backendTypes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { invalidateToken, useAuth } from "../useAuth";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import { useMemo } from "react";
import { useAtom } from "jotai";
import { selectedStoreIdsAtom } from "atoms";

async function fetchSiteRefs({
  session,
  masterOrAdminUser,
  storeIds,
}: SiterefListFetchRequest): Promise<Array<Siteref>> {
  if (!session) {
    throw new Error("User Session not available");
  }
  if (!masterOrAdminUser && !storeIds) {
    throw new Error("No stores available to fetch site refs");
  }
  try {
    // Master and admin users can fetch siterefs for all stores that exist,
    // normal users have to specify a csid to get the siterefs for any given store.
    // Here we check if the user is an admin or master and if so do a single
    // request (faster than multiple requests). When the user is a normal
    // user, we do multiple concurrenct requests for each selected store
    // (see also https://tracify-ai.atlassian.net/browse/HIVE-10)
    if (masterOrAdminUser) {
      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/siteref/list`,
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": session,
          },
        }
      );
      if (response.status !== 200) {
        throw new Error(response.data.error);
      }
      // Inferred return type: Promise<MyData>
      return response.data.result.siterefs as Array<Siteref>;
    } else {
      const promises =
        storeIds?.map((id) =>
          axios.post(
            `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/siteref/list`,
            { csid: id },
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "tracify-token": session,
              },
            }
          )
        ) ?? [];

      const response = await Promise.all(promises);
      const indexWithErrorStatus = response?.findIndex(
        (el) => el.status !== 200
      );

      if (indexWithErrorStatus !== -1) {
        throw new Error(response[indexWithErrorStatus].data.error);
      }
      const allSiteRefs = response.reduce((prev, curr) => {
        if (curr.data?.result?.siterefs) {
          const refs = [
            ...prev,
            ...curr.data?.result?.siterefs,
          ] as Array<Siteref>;
          return refs;
        } else return [...prev];
      }, [] as Siteref[]);
      // Inferred return type: Promise<MyData>
      return allSiteRefs;
    }
  } catch (err: unknown) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const errorMessage = axiosError.response?.data as {
        error: string;
        success: boolean;
      };
      throw new Error(errorMessage.error ?? axiosError.message);
    }
    throw new Error("Unknown error occurred!");
  }
}
export type UseSiteRefsOptions = {
  enabled?: boolean;
  onSuccess?: ((data: Array<Siteref>) => void) | undefined;
};
function useSiteRefs(props: SiterefListRequest, options?: UseSiteRefsOptions) {
  const { data } = useAuth();
  const [selectedStoreIds] = useAtom(selectedStoreIdsAtom);
  const masterOrAdminUser = !!data?.payload?.rol && data?.payload?.rol >= 128;

  const enabled = useMemo(() => {
    const defaultEnabled =
      !!data?.token &&
      (masterOrAdminUser ||
        (!!selectedStoreIds && selectedStoreIds?.length > 0));
    if (options?.enabled) {
      return options.enabled && defaultEnabled;
    }
    return defaultEnabled;
  }, [options, masterOrAdminUser, data, selectedStoreIds]);

  const queryKey: any[] = useMemo(() => {
    const value = ["siterefs"];
    if (!masterOrAdminUser && data?.token && selectedStoreIds) {
      value.push(...selectedStoreIds);
    }
    return value;
  }, [data?.token, masterOrAdminUser, selectedStoreIds]);

  return useQuery({
    queryKey: queryKey,
    queryFn: () =>
      fetchSiteRefs({
        ...props,
        session: data?.token!,
        masterOrAdminUser,
        storeIds: selectedStoreIds,
      }),

    ...options,
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000, // 60 seconds
    enabled: enabled,
  });
}

const useRequestCreateSiteref = () => {
  const queryClient = useQueryClient();
  const { data } = useAuth();
  return useMutation({
    mutationFn: async ({ csid, type }: RequestSiterefCreateRequest) => {
      if (!data?.token) {
        throw new Error("User Session not available");
      }
      if (["tiktok", "fb", "google", "pinterest"].indexOf(type) < 0) {
        throw new Error(
          `This account type (${type}) is currently not supported`
        );
      }
      // console.log("CONNECTING TIKTOK ACC TO CSID", csid);
      // await new Promise(function (resolve) {
      //   setTimeout(resolve, 2000);
      // });
      const formData = new FormData();
      formData.append("csid", csid);
      try {
        // for adconnector map 'fb' to 'facebook'
        const response = await axios.post(
          `https://adconnector.tracify.ai/${
            type !== "fb" ? type : "facebook"
          }/connect/`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
              "tracify-token": data?.token ?? "",
            },
            withCredentials: true,
          }
        );
        if (response.status !== 200) {
          throw new Error(response.data.error);
        }

        // TODO validate result and add types
        const redirect_to = response.data.redirect_to;
        window.location.href = redirect_to;
        return response.data.result;
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          if (!err.response) {
            throw err;
          }
          const axiosError = err as AxiosError;
          const apiError = axiosError.response?.data as APIError;
          throw new Error(apiError?.error ?? axiosError.message);
        }
        throw new Error("Unknown error occurred!");
      }
    },
    onSuccess: (data, values) =>
      queryClient.invalidateQueries({ queryKey: ["siterefs"] }),
  });
};

export { useSiteRefs, useRequestCreateSiteref };
