import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { AnalyticsRequest, AttributionSettings } from "@api/types/backendTypes";
import { toTracifyDateRange } from "@lib/util-functions";
import { LocalStateType } from "@lib/hooks";
import { getTimeZones } from "@vvo/tzdb";
import { getTimerangeFromDates } from "services/DateRangeServices";
dayjs.extend(utc);
dayjs.extend(timezone);

export type PackAnalyticsRequestPayloadProps = {
  storeIds: Array<string>;
  attributeStart: Dayjs;
  attributeEnd: Dayjs;
  collectStart: Dayjs;
  collectEnd: Dayjs;
  lookbackWindow: number;
  attributionModel: string;
  reportTime: string;
  attributionWindow: number;
  adRestrictionList: Array<string>;
  useGuid?: boolean;
  collectAdInfo?: boolean;
  collectAdKpiTimeline?: boolean;
  collectAdKpis?: boolean;
  collectAdInfoFqMode?: boolean;
  fullyQualifiedIds?: boolean;
  utcOffset?: number;
  ignoreAdInfoCache?: boolean;
  isComparedRequest: boolean;
};

/**
 * Creates the request payload for retrieving analytics from tracify.
 *
 * @param {PackAnalyticsRequestPayloadProps} props
 * @param {string[]} props.storeIds List with csids
 * @param {number} props.lookbackWindow The lookback window for new vs returning in days
 * @param {Dayjs} props.attributeStart Attribution start datetime
 * @param {Dayjs} props.attributeEnd Attribution end datetime
 * @param {Dayjs} props.collectStart Collect start datetime
 * @param {Dayjs} props.collectEnd Collect end datetime
 * @param {string} props.attributionModel Attribution model to use.
 * @param {string} props.reportTime Report time to use (event, adview)
 * @param {int} props.attributionWindow Attribution window to use
 * @param {string[]} props.adRestrictionList which providers should be used
 * @param {boolean} props.use_guid default to true
 * @param {string} props.collectAdInfo Whether we should also request ad infos
 * @param {string} props.collectAdKpiTimeline Whether we should also request ad kpis per day
 * @param {string} props.collectAdKpis Whether we should also request ad kpis
 * @param {string} props.collectAdInfoFqMode Whether we should also request ad info in fq mode
 * @param {string} props.fullyQualifiedIds Whether we should request ad ids in fq mode
 * @param {string} props.utcOffset UTC offset needed for daily view
 * @return {AnalyticsRequest} Request payload usable for requests.
 */
export function packAnalyticsRequestPayload({
  storeIds,
  attributeStart,
  attributeEnd,
  collectStart,
  collectEnd,
  attributionModel,
  reportTime,
  attributionWindow,
  adRestrictionList,
  useGuid,
  lookbackWindow,
  collectAdInfo,
  collectAdKpiTimeline,
  collectAdKpis,
  collectAdInfoFqMode,
  fullyQualifiedIds,
  utcOffset,
  ignoreAdInfoCache,
  isComparedRequest,
}: PackAnalyticsRequestPayloadProps): AnalyticsRequest {
  const obj = {} as AnalyticsRequest;

  const attr_ctx = {} as AttributionSettings;
  // which attribution model we want to use first click, last click, etc.
  attr_ctx.attribution =
    attributionModel === "isolated" ? "last" : attributionModel;
  // if we want to attribute by view date or click date
  attr_ctx.time_filtering_type = reportTime;
  // TODO: ask Markus what this does
  attr_ctx.clustering = "interaction_unique";
  // TODO: ask Markus what this does
  attr_ctx.reduce_strategy = "any";

  // the store ids for which we want to get attribution data
  if (storeIds !== undefined) obj.csids = storeIds;
  if (adRestrictionList.length > 0) {
    console.log("Applying restriction on ads: " + adRestrictionList.join(","));
    // which providers we want to include in our attributions
    obj.ads_scope = adRestrictionList;
    if (adRestrictionList.includes("referred")) {
      obj.ads_scope = [...obj.ads_scope, "referral"];
    }
  }
  // when the user clicks on the reload button, we want to ignore the ad-connector cache data
  // https://tracify-ai.atlassian.net/browse/DB-199
  if (ignoreAdInfoCache) {
    obj.ignore_ad_info_cache = true;
  }
  obj.isComparedRequest = isComparedRequest;
  // start time including the attribution window
  obj.collect_start = collectStart.format("YYYY-MM-DD HH:mm:ss");
  // end time as selected in the datepicker
  obj.collect_end = collectEnd.format("YYYY-MM-DD HH:mm:ss");
  // start time not including the attribution window
  attr_ctx.attribution_start = attributeStart.format("YYYY-MM-DD HH:mm:ss");
  // end time as selected in the datepicker
  attr_ctx.attribution_end = attributeEnd.format("YYYY-MM-DD HH:mm:ss");
  // the attribution window that should be added on top of the selected daterange
  attr_ctx.max_attribution = attributionWindow;
  obj.attributions = [attr_ctx];

  // Whether we should also request ad infos from the ad-connector
  obj.collect_ad_info = collectAdInfo;
  // Whether we should also request ad kpis from the ad-connector
  obj.collect_ad_kpis = collectAdKpis;
  // Whether we should also request ad kpis per day from the ad-connector
  obj.collect_ad_kpi_timeline = collectAdKpiTimeline;
  // Whether we should also request ad info in fq mode (to match what we get from hive and the ad-connector with the flags below)
  obj.collect_ad_info_fqmode = collectAdInfoFqMode;
  // Whether we should request ad ids in fq mode
  obj.fully_qualified_ids = true;
  // Whether we should request ad ids in fq mode from the ad-connector
  obj.adsdb_fully_qualified_ids = true;
  // Wheter we want to get the pre-cached data directly with the request (if available)
  obj.allow_result_provision = true;

  if (utcOffset !== undefined) {
    // UTC offset needed for daily view
    obj.utc_offset = utcOffset;
  }

  return obj;
}

export type ToAnalyticsRequestPayloadProps = {
  storeIds: Array<string>;
  startTime: string;
  lookbackWindow: number;
  endTime: string;
  attributionModel: string;
  reportTime: string;
  attributionWindow: number;
  selectedTimezone: string;
  adRestrictionList: Array<string>;
  useGuid?: boolean;
  collectAdInfo?: boolean;
  collectAdKpiTimeline?: boolean;
  collectAdKpis?: boolean;
  collectAdInfoFqMode?: boolean;
  fullyQualifiedIds?: true;
  ignoreAdInfoCache?: boolean;
  isComparedRequest: boolean;
};

/**
 * Creates the request payload for retrieving analytics from tracify.
 *
 * @param {ToAnalyticsRequestPayloadProps} props
 * @param {string} props.storeIds Ids of the store to include for retrieval (csids).
 * @param {number} props.lookbackWindow The lookback window for new vs returning in days
 * @param {string} props.startTime Starting timepoint for analysis range.
 * @param {string} props.endTime Final timepoint for analysis range.
 * @param {string} props.attributionModel Attribution model to apply.
 * @param {string} props.reportTime Time of reporting to use (impression or conversion).
 * @param {number} props.attributionWindow Size of attribution windows (x days).
 * @param {string} props.selectedTimezone Timezone to use.
 * @param {string[]} props.adRestrictionList List with all ads providers included in analysis (e.g.: ["meta", "google"])
 * @param {boolean} props.useGuid default to true
 * @param {string} props.collectAdInfo Wheter we should also request ad infos
 * @param {string} props.collectAdKpiTimeline Wheter we should also request ad kpis per day
 * @param {string} props.collectAdKpis Wheter we should also request ad kpis
 * @param {string} props.collectAdInfoFqMode Wheter we should also request ad info in fq mode
 * @param {string} props.fullyQualifiedIds Wheter we should request ad ids in fq mode
 * @return {AnalyticsRequest} Request payload usable for requests.
 */
export function toAnalyticsRequestPayload({
  storeIds,
  startTime,
  endTime,
  attributionModel,
  lookbackWindow,
  reportTime,
  attributionWindow,
  selectedTimezone,
  adRestrictionList,
  useGuid,
  collectAdInfo,
  collectAdKpiTimeline,
  collectAdKpis,
  collectAdInfoFqMode,
  fullyQualifiedIds,
  ignoreAdInfoCache,
  isComparedRequest,
}: ToAnalyticsRequestPayloadProps): AnalyticsRequest {
  // just for debug
  // console.log("Using timezone: " + selectedTimezone);

  // convert to Dayjs object and keep local time
  // dates get saved in ISO-Format and i.e. using 2022-09-24T22:00:00.000Z with
  // a GMT+0 timezone would end up in having the 24th of September as the date
  // instead of the 25th of september which would be the accurate date in our
  // local timezone
  const offset = dayjs().tz(selectedTimezone).utcOffset();
  const start = dayjs(startTime).startOf("day").utcOffset(offset, true);
  const end = dayjs(endTime).endOf("day").utcOffset(offset, true);

  // check conversion
  // console.log(
  //   `Conversion: ${startTime} -> ${start.toISOString()} || ${endTime} -> ${end.toISOString()}`
  // );

  // convert into tracify range
  const [attributeStart, attributeEnd, collectStart, collectEnd] =
    toTracifyDateRange(start, end, reportTime, attributionWindow);

  if (typeof attributionWindow === "string")
    attributionWindow = parseInt(attributionWindow);

  // build analytics payload
  const payload = packAnalyticsRequestPayload({
    storeIds,
    attributeStart,
    attributeEnd,
    collectStart,
    collectEnd,
    attributionModel,
    reportTime,
    attributionWindow,
    adRestrictionList,
    useGuid,
    lookbackWindow: lookbackWindow,
    collectAdInfo,
    collectAdKpiTimeline,
    collectAdKpis,
    collectAdInfoFqMode,
    fullyQualifiedIds,
    utcOffset: offset / 60,
    ignoreAdInfoCache,
    isComparedRequest,
  });

  // provide payload
  return payload;
}

/**
 * Creates the request payload for retrieving analytics from tracify.
 *
 * @param {AnalyticsRequest} props
 * @param {string[]} props.csids List with csids
 * @param {AttributionSettings[]} props.attributions Attribution start datetime
 * @param {string} props.collect_start Collect start datetime
 * @param {string} props.collect_end Collect end datetime
 * @param {string[]} props.views Attribution model to use.
 * @param {string[]} props.ads_scope which providers should be used
 * @param {number} props.utc_offset UTC offset needed for daily view
 * @return {AnalyticsRequest} Request payload usable for requests.
 */
export function getStateFromAnalyticsRequestPayload({
  ads_scope,
  attributions,
  collect_end,
  collect_start,
  csids,
  views,
  utc_offset = 0,
}: AnalyticsRequest): LocalStateType {
  const obj: LocalStateType = {};

  obj.selectedStoreIds = csids;
  obj.selectedMarketingChannels =
    ads_scope as typeof obj.selectedMarketingChannels;

  const localTimezoneOffset = dayjs().utcOffset();
  if (localTimezoneOffset === (utc_offset ?? 0) * 60) {
    obj.selectedTimezone = dayjs.tz.guess();
  } else {
    const timezones = getTimeZones();
    obj.selectedTimezone =
      timezones.find((el) => el.rawOffsetInMinutes === (utc_offset ?? 0) * 60)
        ?.name ?? dayjs.tz.guess();
  }

  const attribution = attributions[0];
  if (attribution) {
    obj.attributionModel =
      attribution.attribution as typeof obj.attributionModel;
    obj.endTime = dayjs(attribution.attribution_end)
      .add(utc_offset, "hours")
      .toISOString() as typeof obj.endTime;
    obj.startTime = dayjs(attribution.attribution_start)
      .add(utc_offset, "hours")
      .toISOString() as typeof obj.startTime;
    obj.reportTime = attribution.time_filtering_type as typeof obj.reportTime;

    const dateDifference = dayjs(attribution.attribution_start).diff(
      dayjs(collect_start),
      "days"
    );
    obj.timerange = getTimerangeFromDates({
      startDate: obj.startTime!,
      endDate: obj.endTime!,
    });
    obj.attributionWindow = String(
      dateDifference
    ) as typeof obj.attributionWindow;
  }

  return obj;
}
