import React, { useCallback } from "react";
import { format } from "date-fns";
import {
  PREVIOUS_DATE_RANGE_SELECT_OPTIONS,
  START_DATE_RANGE_SELECT_OPTIONS_FULL,
} from "../../constants/constants";
import { Input, Select } from "@ui";
import { Mutable } from "@lib/util-types";

interface DateRangeDisplayerInterface {
  startDate?: string;
  endDate?: string;
  preset?: string;
  isEditable?: boolean;
  isActive?: boolean;
  onOptionSelect?: CallableFunction;
}

export function DateRangeDisplayer({
  preset = "this_month",
  startDate = format(new Date(), "dd-mm-yyyy"),
  endDate = format(new Date(), "dd-mm-yyyy"),
  isEditable = true,
  isActive = false,
  onOptionSelect = () => {},
}: DateRangeDisplayerInterface) {
  const getLabelfromPreset = useCallback((presetValue: string) => {
    const dateRange = [
      ...START_DATE_RANGE_SELECT_OPTIONS_FULL,
      ...PREVIOUS_DATE_RANGE_SELECT_OPTIONS,
    ].filter((option) => option.value === presetValue);
    if (dateRange && dateRange.length !== 0) {
      return dateRange[0]?.label;
    }
    return "Today";
  }, []);

  return (
    <div className="grid grid-cols-3">
      {/* <div className="col-span-1 flex items-center">
        <div
          className={`w-4 h-4 flex-shrink-0 rounded-sm ${
            isActive ? "bg-primary" : "bg-gray-500"
          }`}
        />
        {isEditable ? (
          <Select
            options={
              PREVIOUS_DATE_RANGE_SELECT_OPTIONS as Mutable<
                typeof PREVIOUS_DATE_RANGE_SELECT_OPTIONS
              >
            }
            onChange={(e) => onOptionSelect(e.target.value)}
            value={preset}
            label="Previous Period"
            placeholder="Previous Period"
            id="previous-periodx"
            width="w-40"
            size="small"
            className="pl-2 pr-6"
            borderColor="border-gray-200 dark:border-gray-700 hover:border-gray-400"
          ></Select>
        ) : (
          <div className="text-foreground font-semibold ml-2 w-40 text-sm capitalize">
            {getLabelfromPreset(preset)}
          </div>
        )}
      </div> */}
      <div className="ml-4 col-span-2 flex items-center">
        <Input
          value={startDate}
          status="search"
          size="small"
          disabled
          placeholder="dd.mm.yyyy"
        />
        <hr className="w-6 mx-2" />
        <Input
          value={endDate}
          status="search"
          size="small"
          disabled
          placeholder="dd.mm.yyyy"
        />
      </div>
    </div>
  );
}
