import { useCallback, useEffect, useMemo, useState } from "react";
import Table from "@ui/table";
import { createColumnHelper, Table as ReactTable } from "@tanstack/react-table";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { Input } from "@ui";
import Image from "next/image";
import { BaseScore } from "@api/types/backendTypes";
import InfluencerOutdatedTooltip from "../MarketingOverview/InfluencerOutdatedTooltip";
import { AVAILABLE_MARKETING_CHANNEL_OPTIONS } from "constants/constants";
import MetricNameWithTooltip from "@components/MetricNameWithTooltip/MetricNameWithTooltip";
import { attributionModelAtom } from "atoms";
import { useAtom } from "jotai";
import { useDebounce } from "@lib/hooks";
import useCurrency from "../../../lib/hooks/use-currency";
import { useTheme } from "next-themes";
import useFormat from "@lib/hooks/use-format";
import OrdersTableDisplay from "@components/NumberDisplays/OrdersTableDisplay";
import RevenueTableDisplay from "@components/NumberDisplays/RevenueTableDisplay";
import AovTableDisplay from "@components/NumberDisplays/AovTableDisplay";
import ConversionRateTableDisplay from "@components/NumberDisplays/ConversionRateTableDisplay";
import AtcTableDisplay from "@components/NumberDisplays/AtcTableDisplay";
import ProductviewTableDisplay from "@components/NumberDisplays/ProductviewTableDisplay";
import PageviewTableDisplay from "@components/NumberDisplays/PageviewTableDisplay";
import { initializeBaseScoreValue } from "@api/services/analytics";

type QdTableProps = {
  title: string;
  displayTitle?: boolean;
  data: Array<BaseScore>;
  comparedData?: Array<BaseScore>;
  loading?: boolean;
  progress?: number;
  disableFilter?: boolean;
  type?: (typeof AVAILABLE_MARKETING_CHANNEL_OPTIONS)[number]["value"];
};

const columnHelper = createColumnHelper<BaseScore>();

export const getTotalValueOfAllRows = (
  data: BaseScore[],
  columnId: keyof BaseScore[][number],
  acceptedRowTypes?: string[],
  roundBeforeAdding?: boolean,
  decimals?: number
) => {
  if (data.length > 1) {
    const value = data
      .map((row) => {
        if (
          row.type === "campaign" ||
          row.type === "cooperationLink" ||
          !row.type ||
          (acceptedRowTypes?.includes(row.type) && row[columnId])
        ) {
          if (Array.isArray(row[columnId])) {
            return (row[columnId] as Array<any>)?.length ?? 0;
          }
          return parseFloat(`${row[columnId] ?? 0}`) ?? 0;
        }
        return 0;
      })
      .reduce((prev, curr) =>
        roundBeforeAdding
          ? prev +
            Math.round(curr * Math.pow(10, decimals ?? 0)) /
              Math.pow(10, decimals ?? 0) // this will round the value to the specified decimals
          : prev + curr
      );
    return value;
  } else if (data.length === 1) {
    return parseFloat(`${data[0][columnId]}`);
  } else return 0;
};

export const getTotalValueOfVisibleRows = (
  table: ReactTable<any>,
  columnId: string,
  acceptedRowTypes?: string[]
) => {
  const rows: any[] = table.getRowModel().rows;
  if (rows.length > 1) {
    const value = rows
      .map((row) => {
        if (
          row.original.type === "campaign" ||
          !row.original.type ||
          acceptedRowTypes?.includes(row.original.type)
        ) {
          return parseFloat(row.original[columnId] ?? "0") ?? 0;
        }
        return 0;
      })
      .reduce((prev, curr) => prev + curr);
    return value;
  } else if (rows.length === 1) {
    return rows[0].original[columnId];
  } else return 0;
};

export const getAverageValueOfVisibleRows = (
  table: ReactTable<any>,
  columnId: string,
  acceptedRowTypes?: string[]
) => {
  const rows: any[] = table.getRowModel().rows;
  if (rows.length > 1) {
    let rowsWithValues = 0;
    const value = rows
      .map((row) => {
        if (
          row.original.type === "campaign" ||
          !row.original.type ||
          acceptedRowTypes?.includes(row.original.type)
        ) {
          if (parseFloat(row.original[columnId]) > 0) {
            rowsWithValues += 1;
          }
          return parseFloat(row.original[columnId] ?? "0") ?? 0;
        }
        return 0;
      })
      .reduce((prev, curr) => prev + curr);
    if (value > 0 && rowsWithValues > 0) return value / rowsWithValues;
    else return 0;
  } else if (rows.length === 1) {
    return rows[0].original[columnId];
  } else return 0;
};

function QdTable(props: QdTableProps) {
  const {
    title,
    data,
    comparedData,
    loading,
    progress,
    displayTitle = false,
    disableFilter,
    type,
  } = props;
  const compared = Boolean((comparedData ?? [])?.length > 0);
  const tableData = useMemo(() => {
    if (compared) {
      const newData = [];
      const campaignIds: string[] = [];
      for (const d of data) {
        const comparedCampaign = comparedData?.find(
          (score) => score.fullyQualifiedId === d.fullyQualifiedId
        );
        if (d.fullyQualifiedId) campaignIds.push(d.fullyQualifiedId);
        if (comparedCampaign) {
          // create campaign level data and add found compared value
          const newTopLevel = { ...d, compared: { ...comparedCampaign } };
          for (const adsetIndex in newTopLevel.subRows) {
            if (adsetIndex) {
              const adset = newTopLevel.subRows[adsetIndex];
              const comparedAdset = comparedCampaign.subRows?.find(
                (el) => el.fullyQualifiedId === adset.fullyQualifiedId
              );
              if (comparedAdset) {
                // add adset level data to campaign level data and add compared value
                newTopLevel.subRows[adsetIndex] = {
                  ...adset,
                  compared: { ...comparedAdset },
                };
                for (const adIndex in adset.subRows) {
                  if (adIndex) {
                    const ad = adset.subRows[adIndex];
                    const comparedAd = comparedAdset.subRows?.find(
                      (el) => el.fullyQualifiedId === ad.fullyQualifiedId
                    );
                    if (comparedAd) {
                      // add ad level data to adset->campaign level data and add compared value
                      newTopLevel.subRows[adsetIndex].subRows[adIndex] = {
                        ...ad,
                        compared: { ...comparedAd },
                      };
                    }
                  }
                }
              }
            }
          }
          newData.push(newTopLevel);
        } else {
          newData.push(d);
        }
      }
      if (comparedData) {
        for (const d of comparedData) {
          if (d.fullyQualifiedId && !campaignIds.includes(d.fullyQualifiedId)) {
            const emptyBaseScore = initializeBaseScoreValue({
              baseScoreId: d.refId,
              scope: d.provider,
              type: "campaign",
              fqId: d.fullyQualifiedId,
            });
            newData.push({
              ...emptyBaseScore,
              name: d.name,
              compared: d,
            });
          }
        }
      }
      return newData;
    } else return data;
  }, [compared, data, comparedData]);

  const [filteredData, setFilteredData] = useState(() => tableData);
  const [attributionModel] = useAtom(attributionModelAtom);
  const debouncedAttributionMode = useDebounce(attributionModel, 2000);
  const { resolvedTheme } = useTheme();

  useEffect(() => {
    setFilteredData(tableData);
  }, [tableData]);

  const visibleColumns = useMemo(
    () => ({
      name: true,
      purchaseCount: true,
      purchaseAmount: true,
      productview: true,
      addtocart: true,
      pageview: true,
    }),
    []
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        enableSorting: true,
        enableResizing: type !== "direct",
        enablePinning: type !== "direct",
        header: type === "direct" ? "" : "Name",
        cell: ({ row, getValue, table, ...props }) => {
          let rowValue = ["Organic", "Referral"].includes(title)
            ? getValue()
                .replace("https://", "")
                .replace("http://", "")
                .replace("android-app://", "")
            : getValue();
          if (!rowValue) rowValue = "(no title)";
          const globalFilter = table.getState().globalFilter;
          const isFilteredRow =
            globalFilter !== "" &&
            rowValue.toLowerCase().includes(globalFilter.toLowerCase());

          const isInfluencer =
            ["Influencer"].includes(title) && row.depth === 0;
          const isCooperation =
            ["Influencer"].includes(title) && row.depth === 1;
          return (
            <div
              className="truncate"
              style={{
                // Since rows are flattened by default,
                // we can use the row.depth property
                // and paddingLeft to visually indicate the depth
                // of the row
                paddingLeft: `${row.depth * 2}rem`,
              }}
            >
              {(row.getCanExpand() &&
                row.original?.provider !== "pinterest" &&
                !isCooperation) ||
              (row.getCanExpand() &&
                !(
                  row.original?.type === "adset" &&
                  row.original?.advertisingChannelType === "CATALOG_SALES"
                ) &&
                !isCooperation) ? (
                <button
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    className: `cursor-pointer text-foreground truncate flex max-w-3xl items-center ${
                      isFilteredRow ? "bg-primary" : ""
                    }`,
                  }}
                >
                  <div className="flex-shrink-0">
                    {row.getIsExpanded() ? (
                      <ChevronDownIcon className="h-3 w-3 mr-1" />
                    ) : (
                      <ChevronRightIcon className="h-3 w-3 mr-1" />
                    )}
                  </div>
                  <div className="flex-shrink-0 w-4 h-4">
                    {(row.original?.type === "campaign" || isInfluencer) && (
                      <Image
                        height={16}
                        width={16}
                        alt="campaign-icon"
                        src={`/static/icons/${
                          resolvedTheme === "dark"
                            ? "campaign-icon-2.png"
                            : "campaign-icon-2-dark.png"
                        }`}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                    )}
                    {(row.original?.type === "adset" || isCooperation) && (
                      <Image
                        height={16}
                        width={16}
                        alt="adgroup-icon"
                        src={`/static/icons/${
                          resolvedTheme === "dark"
                            ? "adgroup-icon-2.png"
                            : "adgroup-icon-2-dark.png"
                        }`}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                    )}
                    {row.original?.type === "ad" && (
                      <Image
                        height={16}
                        width={16}
                        alt="ad-icon"
                        src={`/static/icons/${
                          resolvedTheme === "dark"
                            ? "ad-icon-2.png"
                            : "ad-icon-2-dark.png"
                        }`}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                    )}
                  </div>
                  <p
                    className={`${
                      isFilteredRow ? "bg-primary" : ""
                    } truncate ml-1`}
                  >
                    {rowValue}
                  </p>
                </button>
              ) : (
                <div className="flex items-center max-w-3xl">
                  <div className="flex-none">
                    {row.original?.type === "campaign" && (
                      // Needed for Google Max Campaigns who never
                      // expand. Then the directory icon will not be
                      // there and we need a bit of extra margin.
                      <div className="ml-4 h-4 w-4">
                        <Image
                          height={16}
                          width={16}
                          alt="campaign-icon"
                          src={`/static/icons/${
                            resolvedTheme === "dark"
                              ? "campaign-icon-2.png"
                              : "campaign-icon-2-dark.png"
                          }`}
                          style={{
                            maxWidth: "100%",
                            height: "auto",
                          }}
                        />
                      </div>
                    )}
                    {row.original?.type === "adset" && (
                      <Image
                        height={16}
                        width={16}
                        alt="adgroup-icon"
                        src={`/static/icons/${
                          resolvedTheme === "dark"
                            ? "adgroup-icon-2.png"
                            : "adgroup-icon-2-dark.png"
                        }`}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                    )}
                    {row.original?.type === "ad" && (
                      <Image
                        height={16}
                        width={16}
                        alt="ad-icon"
                        src={`/static/icons/${
                          resolvedTheme === "dark"
                            ? "ad-icon-2.png"
                            : "ad-icon-2-dark.png"
                        }`}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                    )}
                  </div>
                  <p
                    className={`${
                      isFilteredRow ? "bg-primary" : ""
                    } truncate ml-1`}
                  >
                    {rowValue}
                  </p>
                </div>
              )}
            </div>
          );
        },
        footer: ({ column }) => {
          const length = column.getFacetedRowModel().rows.length;
          return (
            <span className="font-semibold">
              Total {type !== "direct" ? `(${length ?? 0})` : null}
            </span>
          );
        },
      }),
      columnHelper.accessor("purchaseCount", {
        id: "purchaseCount",
        sortDescFirst: true,
        enableResizing: false,
        size: compared ? 190 : 150,
        cell: (info) => (
          <OrdersTableDisplay<BaseScore>
            info={info}
            compared={compared}
            attributionMode={debouncedAttributionMode}
          />
        ),
        header: ({ header }) => (
          <MetricNameWithTooltip metric={header.id as any} />
        ),
        footer: (info) => {
          return (
            <OrdersTableDisplay<BaseScore>
              info={info}
              compared={compared}
              attributionMode={debouncedAttributionMode}
              calculateTotals
            />
          );
        },
      }),
      columnHelper.accessor("purchaseAmount", {
        enableResizing: false,
        size: compared ? 210 : 150,
        header: ({ header }) => (
          <MetricNameWithTooltip metric={header.id as any} />
        ),
        cell: (info) => (
          <RevenueTableDisplay<BaseScore> info={info} compared={compared} />
        ),
        footer: (info) => (
          <RevenueTableDisplay<BaseScore>
            info={info}
            compared={compared}
            calculateTotals
          />
        ),
      }),
      columnHelper.accessor("aov", {
        enableResizing: false,
        id: "aov",
        size: compared ? 180 : 150,
        cell: (info) => (
          <AovTableDisplay<BaseScore> info={info} compared={compared} />
        ),
        header: ({ header }) => (
          <MetricNameWithTooltip metric={header.id as any} />
        ),
        footer: (info) => (
          <AovTableDisplay<BaseScore>
            info={info}
            compared={compared}
            calculateTotals
          />
        ),
      }),
      columnHelper.accessor("cr", {
        enableResizing: false,
        id: "cr",
        size: compared ? 180 : 150,
        cell: (info) => (
          <ConversionRateTableDisplay<BaseScore>
            info={info}
            compared={compared}
          />
        ),
        header: ({ header }) => (
          <MetricNameWithTooltip metric={header.id as any} />
        ),
        footer: (info) => (
          <ConversionRateTableDisplay<BaseScore>
            info={info}
            compared={compared}
            calculateTotals
          />
        ),
      }),
      columnHelper.accessor("addtocart", {
        enableResizing: false,
        id: "addtocart",
        enableSorting: true,
        size: compared ? 200 : 150,
        cell: (info) => (
          <AtcTableDisplay<BaseScore> info={info} compared={compared} />
        ),
        header: ({ header }) => (
          <MetricNameWithTooltip metric={header.id as any} />
        ),
        footer: (info) => (
          <AtcTableDisplay<BaseScore>
            info={info}
            compared={compared}
            calculateTotals
          />
        ),
      }),

      columnHelper.accessor("productview", {
        enableResizing: false,
        id: "productview",
        enableSorting: true,
        size: compared ? 200 : 150,
        cell: (info) => (
          <ProductviewTableDisplay<BaseScore> info={info} compared={compared} />
        ),
        header: ({ header }) => (
          <MetricNameWithTooltip metric={header.id as any} />
        ),
        footer: (info) => (
          <ProductviewTableDisplay<BaseScore>
            info={info}
            compared={compared}
            calculateTotals
          />
        ),
      }),

      columnHelper.accessor("pageview", {
        enableResizing: false,
        id: "pageview",
        size: compared ? 200 : 150,
        enableSorting: true,
        cell: (info) => (
          <PageviewTableDisplay<BaseScore> info={info} compared={compared} />
        ),
        header: ({ header }) => (
          <MetricNameWithTooltip metric={header.id as any} />
        ),
        footer: (info) => (
          <PageviewTableDisplay<BaseScore>
            info={info}
            compared={compared}
            calculateTotals
          />
        ),
      }),
    ],
    [type, title, resolvedTheme, compared, debouncedAttributionMode]
  );

  let loadingState;
  let emptyState;
  if (loading) {
    loadingState = (
      <div className="relative flex w-[calc(100vw-320px)] justify-center items-center">
        <div className="loader mr-4"></div>
        <p className="text-foreground">
          {`${progress?.toFixed(0)}`} % processed...
        </p>
      </div>
    );
  } else if (!tableData || tableData.length === 0) {
    emptyState = (
      <div className="relative flex w-[calc(100vw-320px)] justify-center items-center">
        <p>
          {loading
            ? "We couldn't find any data for the selected timeframe..."
            : "No data has been loaded..."}
        </p>
      </div>
    );
  }

  const onFilter = useCallback(
    ({
      filterValue,
      table,
    }: {
      filterValue: string;
      table: ReactTable<any>;
    }) => {
      const filterData = (baseData: BaseScore[], value: string) => {
        const newData: BaseScore[] = [];
        if (!value || value === "") return baseData;
        for (const dataIndex in baseData) {
          if (dataIndex && baseData[dataIndex]) {
            const el = { ...baseData[dataIndex] };
            if (
              el.refId.toLowerCase().includes(value?.toLowerCase()) ||
              el.name.toLowerCase().includes(value?.toLowerCase())
            )
              newData.push(el);
            else if (el.subRows) {
              const subrowsData = filterData(el.subRows, value);
              if (subrowsData?.length > 0) {
                el.subRows = subrowsData;
                newData.push(el);
              }
            }
          }
        }
        return newData;
      };
      const filterDataRes = filterValue
        ? filterData(tableData, filterValue)
        : tableData;
      setFilteredData(filterDataRes);
      const getExpandingState = (
        baseData: BaseScore[],
        value: string,
        currentIndex?: string
      ) => {
        let state: Record<string, boolean> = {};
        for (const filterIndex in baseData) {
          if (filterIndex && baseData[filterIndex]) {
            const el = { ...baseData[filterIndex] };
            let newIndex = "";
            if (currentIndex) {
              newIndex = `${currentIndex}.${filterIndex}`;
            } else {
              newIndex = filterIndex;
            }
            if (
              el.refId.toLowerCase().includes(value?.toLowerCase()) ||
              el.name.toLowerCase().includes(value?.toLowerCase())
            ) {
              state = { ...state, [newIndex]: false };
            } else if (el.subRows) {
              const subrowsData = getExpandingState(
                el.subRows,
                value,
                newIndex
              );
              if (subrowsData && Object.keys(subrowsData).length > 0) {
                state = { ...state, [newIndex]: true, ...subrowsData };
              }
            }
          }
        }
        return state;
      };
      const expandingState = getExpandingState(filterDataRes, filterValue);
      table.setExpanded(expandingState);
    },
    [tableData]
  );
  const pinnedColumns = { left: ["name"] };
  return (
    <Table<BaseScore>
      maxHeight="max-h-[500px]"
      columns={columns}
      enableResizing
      loadingState={loadingState}
      emptyState={emptyState}
      // defaultSorting={[{ id: "purchaseCount", desc: true }]}
      header={
        <div className="h-full flex items-center flex-wrap">
          {displayTitle ? (
            <p className={` text-foreground font-semibold`}>{title}</p>
          ) : null}
          {type === "influencer" ? <InfluencerOutdatedTooltip /> : null}
        </div>
      }
      visibleColumns={visibleColumns}
      pinnedColumns={pinnedColumns}
      data={filteredData}
      showFooter={true}
      enableGlobalFilter={true}
      disableRowsPerPage
      hideRows={type === "direct"}
      globalFilterComponent={
        disableFilter ? (
          <></>
        ) : (
          <Filter
            minLength={
              ["Email", "Influencer", "Organic", "Referral"].includes(title)
                ? 0
                : 3
            }
          />
        )
      }
      // @ts-ignore
      onFilter={onFilter}
    />
  );
}

export default QdTable;

function Filter({
  table,
  minLength,
}: {
  table?: ReactTable<any>;
  minLength?: number;
}) {
  const globalFilterValue = table?.getState().globalFilter ?? "";
  const [filterValue, setFilterValue] = useState(globalFilterValue);
  useEffect(() => {
    if (minLength && minLength > 0) {
      if (filterValue.length > minLength - 1) {
        table?.setGlobalFilter(filterValue);
      } else if (globalFilterValue) {
        table?.setGlobalFilter("");
        table?.setExpanded({});
      }
    } else {
      table?.setGlobalFilter(filterValue);
    }
  }, [filterValue, globalFilterValue, table, minLength]);
  return (
    <div className="w-88">
      <Input
        type="text"
        value={filterValue}
        onChange={(e) => {
          setFilterValue(e.target.value);
        }}
        width="100%"
        placeholder={`Search for ID's or names${
          minLength && minLength > 0 ? " (min. " + minLength + " letters)" : ""
        }`}
      />
    </div>
  );
}
