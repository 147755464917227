async function initMocks() {
  if (typeof window === "undefined") {
    // do nothing for now
    // maybe later when we move to RSC
  } else {
    const { worker } = await import("./browser");
    await worker.start();
  }
}

initMocks();

export {};
