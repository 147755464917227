import { APIError, ChiefAPIError } from "@api/types/backendTypes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { ZodError, z } from "zod";
import { useAuth } from "../useAuth";

export const CreateCooperationLinkSchema = z.object({
  title: z.string({ required_error: "Name is required" }),
  cooperation_id: z.string(),
  target_url: z
    .string({
      invalid_type_error:
        "Please enter a valid URL (e.g https://your-domain.com)",
    })
    .url({
      message: "Please enter a valid URL (e.g https://your-domain.com)",
    }),
  existing_utm: z.string().optional(),
  discount_code: z.string().optional(),
  url_shortener: z.string().optional(),
  active: z.boolean(),
  customer_site_id: z.string().nullish(),
});

export type CreateCooperationLinkInput = z.infer<
  typeof CreateCooperationLinkSchema
>;

const onCreateCooperationLink = async (
  input: CreateCooperationLinkInput,
  token: string
) => {
  if (!token) {
    throw new Error(
      "Invalid token provided! Please provide a valid kytron token."
    );
  }
  try {
    CreateCooperationLinkSchema.parse(input);
  } catch (error) {
    if (error instanceof z.ZodError) {
      const message = error.issues[0]?.message;
      throw new Error(message ?? "Unkown error occurred!");
    }
    throw new Error("Unkown error occurred!");
  }
  try {
    // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_CHIEF_V4_BASE_URL}/influencers/links/create`,
      {
        title: input.title,
        cooperation_id: input.cooperation_id,
        target_url: input.target_url,
        existing_utm: input.existing_utm,
        discount_code: input.discount_code,
        url_shortener: input.url_shortener,
        active: input.active,
        customer_site_id: input.customer_site_id,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    return response.data;
  } catch (err: any) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const apiError = axiosError.response?.data as ChiefAPIError;
      const firstErr = apiError.detail[0];
      throw new Error(firstErr?.msg ?? axiosError.message);
    }
    throw new Error("Unkown error occurred!");
  }
};

export const useCreateCooperationLink = () => {
  const queryClient = useQueryClient();
  const { data } = useAuth();
  return useMutation({
    mutationFn: (input: CreateCooperationLinkInput) =>
      onCreateCooperationLink(input, data?.token ?? ""),
    onSuccess: async (data, values) => {
      queryClient.invalidateQueries({ queryKey: ["cooperationLinks"] });
      queryClient.invalidateQueries({ queryKey: ["cooperations"] });
      queryClient.invalidateQueries({ queryKey: ["cooperation"] });
      queryClient.invalidateQueries({ queryKey: ["influencers"] });
      queryClient.invalidateQueries({ queryKey: ["influencer"] });
    },
  });
};
