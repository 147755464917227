import { COLUMN_OPTIONS } from "constants/constants";

export const metricsInformation = {
  cac: "Customer Acquisition Cost (CAC): The total cost you spend to acquire a new customer. Marketing spend divided by the number of new customers.",
  cpo: "Cost per order (CPO): The total cost you spend to generate one order with your marketing. This also includes returning customers. Marketing spend divided by the number of orders.",
  newVisitorRate:
    "The rate of new visitors in your store. It shows what percentage of your visitors have visited your store for the first time. New visitors divided by all visitors",
  newCustomerRate:
    "The rate of new customers of your store. It shows what percentage of your customers are actually new customers. New customers divided by all customers.",
  pageview:
    "Unique Page Views (uPV): The amount of page views from individual persons. Unique means if a person views two pages, we only report one. Helps you understand how many single individuals visited your store. Be aware: Not every link click results in a page view because people bounce within the process of getting directed to a landing page.",
  productview:
    "Unique Content Views (uCV): The amount of product page views from individual persons. Unique means if a person views two product pages, we only report one. Helps you understand how many single individuals visited product pages in your store.",
  addtocart:
    "Unique Add to Carts (uATC): The amount of add to carts from individual persons. Unique means if a person clicks the add to cart button twice, we only report one add to cart. You do not get misled by multiple add to cards from a single person.",
  checkout:
    "Unique Checkouts Initiated (uIC): The number of checkouts started from individual persons. Unique means if a person goes into the checkout twice, we only report one checkout event. You do not get misled by multiple checkouts a single person started.",
  aov: "Average order value (AOV). It's the average basket size of your orders, meaning how much a customer on average spends with you. Revenue divided by amount of orders.",
  cr: "The share of your visitors who placed an order in your store. Orders divided by visitors.",
  frequency:
    "The frequency your ads get displayed on average to an individual person. A frequency of 1.8 means that on average the people in your audience have seen your ad 1.8 times in the timeframe you selected. ",
  cpc: "Cost per link click (CPC). How much do you pay on average for one click on your ad for example. Spend on the platform divided by total link clicks. Be aware: Not every link click results in a page view because people bounce within the process of getting directed to a landing page.",
  cpm: "Cost per mille (CPM): The average amount you’ve spent for 1000 impressions in the selected timeframe.",
  ctr: "Click-through rate (CTR): The percentage of how many of the people who see your ad click on it.",
  reach:
    "The number of users who saw your ads at least once. Reach is different from Impressions: A user can be shown your ads several times (impressions), while in terms of reach, you have just one user.",
  impressions: "The number of times your ads were shown.",
  linkClick:
    "The number of clicks that lead to a specified destination. Be aware: Not every link click results in a page view because people bounce within the process of getting directed to a landing page.",
  roas: "Return on advertising spend (ROAS): The relation between generated revenue and spent marketing budget. The ROAS is calculated by dividing the revenue by the marketing spent.",
  ihc: "This principle (IHC) defines on which position in the customer journey a channel places. Initiator means that the channel initiates the customer journey. Holder means that it is more of a retargeting/mid-funnel channel. Closer means that the channel captures the demand and closes the user. For instance: Meta is a typical Initiator channel and Google a typical closer channel.",
  videoAvgTimeWatchedActions:
    "The average amount of time your video was played per single video view, including any time spent replaying the video.",
  thumbstopRatio:
    "The percentage of people who saw 3 seconds or more of your video ad. Helps you understand if your video caught the attention of your audience. Also known as scrollstopper rate or hook rate.",
  acquisitionRoas:
    "Acquisition return on advertising spend (aROAS) / New customer ROAS: The relation between generated revenue from new customers and spent marketing budget. The Acquisition ROAS is calculated by dividing the new customer revenue by the marketing spent.",
} as Record<(typeof COLUMN_OPTIONS)[number]["value"] | "ihc", string>;

export const generalMetricsInformation = {
  ...metricsInformation,
  cpo: "Cost per action (CPA): The total cost you spend to generate one conversion with your marketing. This also includes returning customers. Marketing spend divided by the number of conversions.",
  aov: "Average conversion value (ACV). It's the average conversion value of your conversions, meaning how much a conversion on average is worth to you. Conversion value divided by amount of conversions.",
} as Record<(typeof COLUMN_OPTIONS)[number]["value"] | "ihc", string>;
