import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { invalidateToken, useAuth } from "../useAuth";
import { transformToOrganisations } from "./transformations";
import { Organisation } from "./types";
import { ChiefAPIError } from "@api/types/backendTypes";

export const FetchOrganisationsSchema = z.object({
  token: z.string({ required_error: "User Session not available" }),
});

export type FetchOrganisationsProps = z.infer<typeof FetchOrganisationsSchema>;

async function fetchOrganisations({
  token,
}: FetchOrganisationsProps): Promise<Array<Organisation>> {
  if (!token) {
    throw new Error("User Session not available");
  }
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_CHIEF_V4_BASE_URL}/organisations/list`,
      {
        params: {
          limit: 100000,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    // Inferred return type: Promise<MyData>
    return transformToOrganisations(response.data.items);
  } catch (err: unknown) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const apiError = axiosError.response?.data as ChiefAPIError;
      const firstErr = apiError.detail[0];
      throw new Error(firstErr?.msg ?? axiosError.message);
    }
    throw new Error("Unknown error occurred!");
  }
}

export type UseOrganisationsOptions = {
  enabled?: boolean;
  onSuccess?: ((data: Array<Organisation>) => void) | undefined;
};

export function useOrganisations(options?: UseOrganisationsOptions) {
  const { data } = useAuth();
  return useQuery({
    queryKey: ["organisations"],
    queryFn: () => fetchOrganisations({ token: data?.token! }),

    ...options,
    refetchOnWindowFocus: process.env.NEXT_PUBLIC_API_MOCKING !== "enabled",
    staleTime: process.env.NEXT_PUBLIC_API_MOCKING !== "enabled" ? 0 : 300, // 0 seconds needed to overwrite global settings
    gcTime: process.env.NEXT_PUBLIC_API_MOCKING !== "enabled" ? 0 : 300, // 0 seconds
    enabled:
      options?.enabled !== undefined
        ? options.enabled && !!data?.token
        : !!data?.token,
  });
}
