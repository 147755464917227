import {
  atomsInitializedAtom,
  attributionModelAtom,
  attributionWindowAtom,
  comparedAtom,
  comparePeriodAtom,
  dashboardAccessAtom,
  endTimeAtom,
  openExportModalAtom,
  previousEndTimeAtom,
  previousStartTimeAtom,
  reportTimeAtom,
  selectedColumnsAtom,
  selectedFiltersAtom,
  selectedMarketingChannelsAtom,
  selectedStoreIdsAtom,
  selectedStoreOptionsAtom,
  selectedTabAtom,
  selectedTimezoneAtom,
  startTimeAtom,
  tablesToExportAtom,
  viewToExportAtom,
  timeRangeKeyAtom,
  influencerFilterAtom,
  placementTypeFilterAtom,
  newVsReturningAtom,
  creativesRowSelectionAtom,
  firstCreativeComparisonMetricAtom,
  secondCreativeComparisonMetricAtom,
  selectedCreativeChartAtom,
  sortByOptionsAtom,
  creativeChannelSelectionAtom,
  showAttributionModeBannerAtom,
  hasChangedAttributionModeAtom,
} from "atoms";
import {
  ATTRIBUTION_MODEL_OPTIONS,
  ATTRIBUTION_WINDOW_OPTIONS,
  AVAILABLE_MARKETING_CHANNEL_OPTIONS,
  PREVIOUS_DATE_RANGE_SELECT_OPTIONS,
  REPORT_TIME_OPTIONS,
  START_DATE_RANGE_SELECT_OPTIONS_FULL,
  TRACIFY_APP_STATE_VAR,
} from "constants/constants";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { useInitFromLocalState } from "./use-init-from-local-state";
import { useRouter } from "next/router";
import {
  getStartDateRange,
  getTimerangeFromDates,
} from "services/DateRangeServices";
import dayjs from "dayjs";
import useSelectedStores from "./use-selected-stores";
import { getLocalStorage } from "@lib/util-functions/getLocalStorage";

export type LocalStateType = {
  startTime?: string;
  endTime?: string;
  attributionModel?: (typeof ATTRIBUTION_MODEL_OPTIONS)[number]["value"];
  attributionWindow?: (typeof ATTRIBUTION_WINDOW_OPTIONS)[number]["value"];
  timerange?: (typeof START_DATE_RANGE_SELECT_OPTIONS_FULL)[number]["value"];
  comparePeriod?: (typeof PREVIOUS_DATE_RANGE_SELECT_OPTIONS)[number]["value"];
  reportTime?: (typeof REPORT_TIME_OPTIONS)[number]["value"];
  selectedMarketingChannels?: Array<
    (typeof AVAILABLE_MARKETING_CHANNEL_OPTIONS)[number]["value"]
  >;
  selectedTimezone?: string;
  selectedStoreIds?: string[];
  compared?: boolean;
};

export function useInitializeAtoms() {
  const [atomsInitialized, setAtomsInitialized] = useAtom(atomsInitializedAtom);
  const { selectedOrganisationData, organisations, isInitialized } =
    useSelectedStores();

  const [newVsReturning] = useAtom(newVsReturningAtom);
  const { pathname, query } = useRouter();
  const [startTime, setStartTime] = useAtom(startTimeAtom);
  const [endTime, setEndTime] = useAtom(endTimeAtom);
  const [attributionModel] = useAtom(attributionModelAtom);
  const [attributionWindow, setAttributionWindow] = useAtom(
    attributionWindowAtom
  );
  const [timerange, setTimerange] = useAtom(timeRangeKeyAtom);
  const [comparePeriod] = useAtom(comparePeriodAtom);
  const [reportTime] = useAtom(reportTimeAtom);
  const [selectedMarketingChannels] = useAtom(selectedMarketingChannelsAtom);
  const [selectedTimezone] = useAtom(selectedTimezoneAtom);
  const [selectedStoreIds] = useAtom(selectedStoreIdsAtom);
  const [compared] = useAtom(comparedAtom);
  useAtom(showAttributionModeBannerAtom);
  useAtom(hasChangedAttributionModeAtom);
  useAtom(previousStartTimeAtom);
  useAtom(previousEndTimeAtom);
  useAtom(selectedFiltersAtom);
  useAtom(selectedStoreOptionsAtom);
  useAtom(selectedColumnsAtom);
  useAtom(selectedTabAtom);
  useAtom(tablesToExportAtom);
  useAtom(openExportModalAtom);
  useAtom(dashboardAccessAtom);
  useAtom(viewToExportAtom);
  useAtom(influencerFilterAtom);
  useAtom(placementTypeFilterAtom);
  useAtom(creativesRowSelectionAtom);
  useAtom(firstCreativeComparisonMetricAtom);
  useAtom(secondCreativeComparisonMetricAtom);
  useAtom(selectedCreativeChartAtom);
  useAtom(sortByOptionsAtom);
  useAtom(creativeChannelSelectionAtom);
  const initializeState = useInitFromLocalState();
  useEffect(() => {
    if (selectedOrganisationData || isInitialized) {
      if (atomsInitialized) return;
      let oldAttributionWindow = 0;
      try {
        const oldAiAttributionWindows = JSON.parse(
          getLocalStorage()?.getItem("ai_attribution_window") ?? "{}"
        ) as Record<string, number>;
        if (oldAiAttributionWindows) {
          const csids =
            (query?.csids
              ? typeof query?.csids === "string"
                ? query.csids?.split(",")
                : query.csids
              : selectedOrganisationData?.customerSites.map((el) => el.id)) ??
            [];
          for (const storeId of csids) {
            if (oldAiAttributionWindows[storeId] > oldAttributionWindow) {
              oldAttributionWindow = oldAiAttributionWindows[storeId];
            }
          }
          if (oldAttributionWindow > 0) {
            setAttributionWindow(
              (oldAttributionWindow !== 80
                ? `${oldAttributionWindow}`
                : "60") as typeof attributionWindow
            );
          }
          getLocalStorage()?.removeItem("ai_attribution_window");
        }
      } catch (error) {
        console.log("Couldn't load old ai attribution window");
      }
      const queryKeys = Object.keys(query);
      if (
        oldAttributionWindow > 0 &&
        queryKeys.includes("attributionWindow") === false
      ) {
        queryKeys.push("attributionWindow");
      }
      const importantQueryKeys = [
        "startTime",
        "endTime",
        "attributionModel",
        "attributionWindow",
        "reportTime",
        "selectedMarketingChannels",
        "timerange",
        "csids",
      ];

      const includesImportantKeys = queryKeys.reduce((prev, curr) => {
        if (importantQueryKeys.includes(curr)) return [...prev, curr];
        return prev;
      }, [] as string[]);

      if (
        includesImportantKeys.includes("startTime") ||
        includesImportantKeys.includes("endTime") ||
        includesImportantKeys.includes("timerange")
      ) {
        // if any of the important keys are present in the current query, we don't want to override the state with a cached job
        const timerangeFromQuery = query["timerange"];
        const timerangeFromOptions = START_DATE_RANGE_SELECT_OPTIONS_FULL.find(
          (el) => el.value === timerangeFromQuery
        )?.value;
        const startTimeFromQuery = query["startTime"];
        const endTimeFromQuery = query["endTime"];
        if (timerangeFromOptions && timerangeFromOptions !== "Custom") {
          const dateRange = getStartDateRange(timerangeFromOptions);
          if (timerangeFromOptions !== timerange) {
            setTimerange(timerangeFromOptions as typeof timerange);
          }
          if (dateRange.startDate) {
            setStartTime(
              dayjs(dateRange.startDate)
                .startOf("day") // set minute and second to 0 to make client-side caching easier
                .toISOString()
            );
          }
          if (dateRange.endDate) {
            setEndTime(
              dayjs(dateRange.endDate)
                .endOf("day") // set minute and second to 0 to make client-side caching easier
                .toISOString()
            );
          }
        } else if (
          typeof startTimeFromQuery === "string" &&
          dayjs(startTimeFromQuery).isValid() &&
          typeof endTimeFromQuery === "string" &&
          dayjs(endTimeFromQuery).isValid() &&
          dayjs(endTimeFromQuery).isAfter(dayjs(startTimeFromQuery))
        ) {
          const timerangeFromDates = getTimerangeFromDates({
            startDate: startTime,
            endDate: endTime,
          });
          setTimerange(timerangeFromDates);
        } else if (
          typeof startTimeFromQuery === "string" &&
          dayjs(startTimeFromQuery).isValid() &&
          typeof endTimeFromQuery === "string" &&
          dayjs(endTimeFromQuery).isValid() &&
          !dayjs(endTimeFromQuery).isAfter(dayjs(startTimeFromQuery))
        ) {
          setTimerange("today");
          setStartTime(dayjs().startOf("day").toISOString());
          setEndTime(dayjs().endOf("day").toISOString());
        } else {
          setTimerange("Custom");
        }
      }

      const localState: LocalStateType = JSON.parse(
        localStorage.getItem(TRACIFY_APP_STATE_VAR) ?? "{}"
      );
      for (const key of includesImportantKeys) {
        if (key === "csids") delete localState["selectedStoreIds"];
        delete localState[key as keyof typeof localState];
      }

      const keys = Object.keys(localState);
      initializeState(localState, keys);

      return setAtomsInitialized(true);
    }
  }, [
    atomsInitialized,
    attributionModel,
    attributionWindow,
    comparePeriod,
    compared,
    endTime,
    initializeState,
    isInitialized,
    newVsReturning,
    organisations?.length,
    pathname,
    query,
    reportTime,
    selectedMarketingChannels,
    selectedOrganisationData,
    selectedStoreIds,
    selectedTimezone,
    setAtomsInitialized,
    setAttributionWindow,
    setEndTime,
    setStartTime,
    setTimerange,
    startTime,
    timerange,
  ]);

  useEffect(() => {
    if (atomsInitialized && selectedStoreIds?.length) {
      const state: LocalStateType = {
        selectedStoreIds,
        attributionModel,
        attributionWindow,
        compared,
        comparePeriod,
        endTime,
        reportTime,
        selectedMarketingChannels,
        selectedTimezone,
        startTime,
        timerange,
      };
      localStorage.setItem(TRACIFY_APP_STATE_VAR, JSON.stringify(state));
    }
    // we want to check for the string of selectedstores and not the array
    // because the array will force a rerun every time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    atomsInitialized,
    attributionModel,
    attributionWindow,
    comparePeriod,
    compared,
    endTime,
    reportTime,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    selectedMarketingChannels?.join(","),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    selectedStoreIds?.join(","),
    selectedTimezone,
    startTime,
    timerange,
  ]);
}
