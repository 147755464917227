import React, { useEffect } from "react";
import { PageHeaderInterface } from "../../../interface/PageHeaderInterface";
import DatePickerPopover from "@components/Datepicker/DatePickerPopover";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useTimeRangeLabel } from "@lib/hooks/useTimeRangeLabel";
import { comparedAtom } from "atoms";
import { useAtom } from "jotai";
import AttributionMenu from "@components/AttributionMenu/AttributionMenu";

export function DatePageHeader({
  title,
  maxDate,
  minDate,
  children,
  hideAttributionMenu = false,
  enableCompare = false,
  requiredChannel,
}: PageHeaderInterface) {
  const { label, comparedLabel } = useTimeRangeLabel();
  const [isCompared, setIsCompared] = useAtom(comparedAtom);
  useEffect(() => {
    if (!enableCompare && isCompared) {
      setIsCompared(false);
    }
  }, [isCompared, enableCompare, setIsCompared]);

  return (
    <>
      <div className="grid grid-cols-3 items-center gap-2 relative z-navbar">
        <div className="w-full col-span-3 sm:col-span-2 flex items-center">
          {typeof title === "string" ? <h2 className="h2">{title}</h2> : title}
        </div>
        <div className="w-full flex justify-end items-start self-start lg:min-w-[320p] col-span-3 sm:col-span-1 ">
          {children}

          <div className="w-fit flex-none">
            <DatePickerPopover
              isCompared={enableCompare}
              maxDate={maxDate}
              minDate={minDate}
            >
              <div className="px-3 h-12 w-fit 2xl:px-8 bg-gray-200 dark:bg-gray-700 rounded-md  cursor-pointer flex flex-none items-center justify-center">
                <div className="">
                  <div className="text-xs text-foreground text-center">
                    {label}
                  </div>
                  {comparedLabel && enableCompare ? (
                    <div className="w-full text-xs text-foreground-soft text-center">
                      {comparedLabel}
                    </div>
                  ) : null}
                </div>
                <div className="ml-2">
                  <ChevronDownIcon
                    color={"var(--color-foreground)"}
                    className="h-4 w-4"
                  />
                </div>
              </div>
            </DatePickerPopover>
          </div>
        </div>
        {hideAttributionMenu ? null : (
          <div className="w-full flex justify-end col-span-3">
            <AttributionMenu requiredChannel={requiredChannel} />
          </div>
        )}
      </div>
    </>
  );
}
