import dayjs from "dayjs";
import {
  CooperationLink,
  CooperationLinkAPIResponse,
  CooperationLinksAPIResponse,
} from "./types";

export const transformToCooperationLink = (
  link: CooperationLinkAPIResponse
) => {
  const cooperationLink: CooperationLink = {
    id: link.id,
    title: link.title,
    cooperationId: link.cooperation_id,
    active: link.active,
    csid: link.customer_site_id,
    targetUrl: link.target_url,
    fullUrl: link.full_url,
    discountCode: link.discount_code,
    existingUTM: link.existing_utm,
    shortenedUrl: link.shortened_url,
    createdAt: link.created_at ? dayjs(link.created_at).toDate() : new Date(),
  };
  return cooperationLink;
};

export const transformToCooperationLinks = (
  data: CooperationLinksAPIResponse
) => {
  const links: Array<CooperationLink> = data.map((link) =>
    transformToCooperationLink(link)
  );
  return links;
};
