import React, { useEffect, useMemo, useState } from "react";
import QdTable from "./QdTable";
import dayjs from "dayjs";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import { AnalyticsResultType, useAnalytics } from "@lib/api-hooks/useAnalytics";
import QdTableContainer from "./QdTableContainer";
import { useAtom } from "jotai";
import { NvrType, attributionModelAtom, newVsReturningAtom } from "atoms";
import NvrDropdown from "@components/NvrDropdown/NvrDropdown";
import {
  START_DATE_RANGE_SELECT_OPTIONS_FULL,
  realtimeDashboardSettings,
} from "constants/constants";
import { DatePageHeader } from "@components/Layout/PageHeader/DatePageHeader";
import { useInitialTimeRange } from "@lib/hooks/use-initial-timerange";
import { useLoadingState } from "@lib/hooks/use-loading-state";
import PageLoadingButton from "@components/PageLoadingButton/PageLoadingButton";
import { empty_aggregated_data } from "@/constants/empty-dashboard-data";
import { useInitializeAttributionSettings } from "@lib/hooks/use-initialize-attribution-settings";

const getAdsData = ({
  data,
  newVsReturning,
}: {
  data: AnalyticsResultType;
  newVsReturning: NvrType;
}) => {
  if (newVsReturning === "default") {
    return data?.tc_aggregated;
  } else if (newVsReturning === "all") {
    return data?.nvr_tc_aggregated?.all;
  } else if (newVsReturning === "new") {
    return data?.nvr_tc_aggregated?.new;
  } else if (newVsReturning === "returning") {
    return data?.nvr_tc_aggregated?.returning;
  }
};

/**
 * Sleep for given number of milliseconds.
 *
 * @param {number} milliseconds Number of milliseconds to sleep for.
 * @return {Promise} Promise which resolves after milliseconds passed.
 */
export function sleep(milliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export function QdDashboard() {
  const { isPending } = useSelectedStores();
  const [newVsReturning] = useAtom(newVsReturningAtom);
  const maxDate = dayjs()
    .hour(23)
    .minute(59)
    .second(59)
    .millisecond(59)
    .toDate();
  const initialTimeRange = useMemo(
    () => ({
      startDate: dayjs().subtract(7, "days").startOf("day").toDate(),
      endDate: dayjs().subtract(1, "days").endOf("day").toDate(),
      timerange:
        "last_7_days" as (typeof START_DATE_RANGE_SELECT_OPTIONS_FULL)[number]["value"],
    }),
    []
  );
  const { initialTimeRangeIsSet } = useInitialTimeRange({
    maxDate,
    initialTimeRange,
  });
  const {
    progress,
    finished,
    isFetching: fetchingData,
    data,
    refetch: onFetchData,
    showNvrTooltip,
    hasData,
  } = useAnalytics(
    {
      ...realtimeDashboardSettings(Boolean(newVsReturning !== "default")),
    },
    {
      enabled: initialTimeRangeIsSet && !isPending,
    }
  );
  const { showLoadingState, isLoadingInitialData } = useLoadingState({
    finished,
    fetchingData,
  });
  const [adsData, setAdsData] = useState(
    hasData ? getAdsData({ data, newVsReturning }) : empty_aggregated_data()
  );

  const [comparedAdsData, setComparedAdsData] = useState(
    hasData && data?.compared
      ? getAdsData({ data: data?.compared, newVsReturning })
      : null
  );

  useEffect(() => {
    // We do this in useEffect now (not useMemo) because we only want to update data if the fetching is finished
    // so both the adsData and comparedAdsData are updated at the same time and we keep the old data until then
    if (!fetchingData) {
      setAdsData(
        hasData ? getAdsData({ data, newVsReturning }) : empty_aggregated_data()
      );
      setComparedAdsData(
        hasData && data?.compared
          ? getAdsData({ data: data?.compared, newVsReturning })
          : null
      );
    }
  }, [data, fetchingData, hasData, newVsReturning]);

  return (
    <div className="relative text-foreground w-full flex flex-col justify-center m-auto">
      <div className="sticky top-[62px] lg:top-0 bg-background opacity-100 py-2 z-header">
        <DatePageHeader
          title="Realtime Dashboard"
          maxDate={maxDate}
          enableCompare
        >
          <div className="flex space-x-2 items-center mr-2">
            <PageLoadingButton
              fetchingData={isPending || (fetchingData && !finished)}
              isPending={isPending}
              onFetchData={onFetchData}
              progress={progress}
              showNvrTooltip={showNvrTooltip}
            />
            <NvrDropdown />
          </div>
        </DatePageHeader>
      </div>

      <div className="relative">
        {hasData || showLoadingState || isLoadingInitialData ? null : (
          <>
            <div className="bg-background/10 backdrop-blur-sm absolute inset-0 z-40 rounded-md -m-2 text-center py-16">
              <div className="sticky top-[350px] inset-x-0 text-foreground">
                <p className="font-semibold text-lg">No Data Available</p>
                <p className="mt-4">
                  There is no data available for the selected timeframe. Please
                  choose a different one.
                </p>
              </div>
            </div>
          </>
        )}
        {(!!adsData?.campaigns?.get("facebook")?.length || !finished) && (
          <QdTableContainer
            loading={showLoadingState}
            title="Meta"
            channel={"facebook"}
            titleColor="bg-qdFacebookMeta"
          >
            <div className="-mt-[52px]">
              <QdTable
                title="Meta"
                data={adsData?.campaigns?.get("facebook") || []}
                comparedData={comparedAdsData?.campaigns?.get("facebook") || []}
                loading={showLoadingState}
                progress={progress}
              />
            </div>
          </QdTableContainer>
        )}
        {(!!adsData?.campaigns?.get("google")?.length || !finished) && (
          <QdTableContainer
            loading={showLoadingState}
            title="Google"
            channel={"google"}
            titleColor="bg-qdGoogle"
          >
            <div className="-mt-[52px]">
              <QdTable
                title="Google"
                data={adsData?.campaigns?.get("google") || []}
                comparedData={comparedAdsData?.campaigns?.get("google") || []}
                loading={showLoadingState}
                progress={progress}
              />
            </div>
          </QdTableContainer>
        )}
        {(!!adsData?.campaigns?.get("tiktok")?.length || !finished) && (
          <QdTableContainer
            loading={showLoadingState}
            title="TikTok"
            channel={"tiktok"}
            titleColor="bg-qdTikTok"
          >
            <div className="-mt-[52px]">
              <QdTable
                title="TikTok"
                data={adsData?.campaigns?.get("tiktok") || []}
                comparedData={comparedAdsData?.campaigns?.get("tiktok") || []}
                loading={showLoadingState}
                progress={progress}
              />
            </div>
          </QdTableContainer>
        )}
        {(!!adsData?.campaigns?.get("influencer")?.length ||
          !!adsData?.campaigns?.get("influencer_module")?.length ||
          !finished) && (
          <QdTableContainer
            loading={showLoadingState}
            title="Influencer"
            channel={"influencer_module"}
            titleColor="bg-qdInfluencer"
          >
            <div
              className={
                adsData?.campaigns?.get("influencer_module")?.length
                  ? "-mt-[52px]"
                  : ""
              }
            >
              {adsData?.campaigns?.get("influencer_module") &&
              adsData?.campaigns?.get("influencer_module")!.length > 0 ? (
                <QdTable
                  title="Influencer"
                  data={adsData?.campaigns?.get("influencer_module") || []}
                  comparedData={
                    comparedAdsData?.campaigns?.get("influencer_module") || []
                  }
                  loading={showLoadingState}
                  progress={progress}
                />
              ) : null}
              {adsData?.campaigns?.get("influencer") &&
              adsData?.campaigns?.get("influencer")!.length > 0 ? (
                <QdTable
                  title="Outdated"
                  displayTitle={true}
                  data={adsData?.campaigns?.get("influencer") || []}
                  comparedData={
                    comparedAdsData?.campaigns?.get("influencer") || []
                  }
                  loading={showLoadingState}
                  progress={progress}
                  type={"influencer"}
                  // disableFilter={true}
                />
              ) : null}
            </div>
          </QdTableContainer>
        )}
        {(!!adsData?.campaigns?.get("email")?.length || !finished) && (
          <QdTableContainer
            loading={showLoadingState}
            title="Email"
            channel={"email"}
            titleColor="bg-qdKlaviyo"
          >
            <div className="-mt-[52px]">
              <QdTable
                title="Email"
                data={adsData?.campaigns?.get("email") || []}
                comparedData={comparedAdsData?.campaigns?.get("email") || []}
                loading={showLoadingState}
                progress={progress}
              />
            </div>
          </QdTableContainer>
        )}
        {(!!adsData?.campaigns?.get("whatsapp")?.length || !finished) && (
          <QdTableContainer
            loading={showLoadingState}
            title="WhatsApp"
            channel={"whatsapp"}
            titleColor="bg-qdWhatsapp"
          >
            <div className="-mt-[52px]">
              <QdTable
                title="WhatsApp"
                data={adsData?.campaigns?.get("whatsapp") || []}
                comparedData={comparedAdsData?.campaigns?.get("whatsapp") || []}
                loading={showLoadingState}
                progress={progress}
              />
            </div>
          </QdTableContainer>
        )}
        {(!!adsData?.campaigns?.get("msads")?.length || !finished) && (
          <QdTableContainer
            loading={showLoadingState}
            title="Microsoft Ads"
            channel={"msads"}
            titleColor="bg-qdMicrosoft"
            beta
          >
            <div className="-mt-[52px]">
              <QdTable
                title="Microsoft Ads"
                data={adsData?.campaigns?.get("msads") || []}
                comparedData={comparedAdsData?.campaigns?.get("msads") || []}
                loading={showLoadingState}
                progress={progress}
              />
            </div>
          </QdTableContainer>
        )}
        {(!!adsData?.campaigns?.get("pinterest")?.length || !finished) && (
          <QdTableContainer
            loading={showLoadingState}
            title="Pinterest"
            channel={"pinterest"}
            titleColor="bg-qdPinterest"
          >
            <div className="-mt-[52px]">
              <QdTable
                title="Pinterest"
                data={adsData?.campaigns?.get("pinterest") || []}
                comparedData={
                  comparedAdsData?.campaigns?.get("pinterest") || []
                }
                loading={showLoadingState}
                progress={progress}
              />
            </div>
          </QdTableContainer>
        )}
        {(!!adsData?.campaigns?.get("snapchat")?.length || !finished) && (
          <QdTableContainer
            loading={showLoadingState}
            title="Snapchat"
            channel={"snapchat"}
            titleColor="bg-qdSnapchat"
            beta
          >
            <div className="-mt-[52px]">
              <QdTable
                title="Snapchat"
                data={adsData?.campaigns?.get("snapchat") || []}
                comparedData={comparedAdsData?.campaigns?.get("snapchat") || []}
                loading={showLoadingState}
                progress={progress}
              />
            </div>
          </QdTableContainer>
        )}
        {(!!adsData?.campaigns?.get("taboola")?.length || !finished) && (
          <QdTableContainer
            loading={showLoadingState}
            title="Taboola"
            channel={"taboola"}
            titleColor="bg-qdTaboola"
          >
            <div className="-mt-[52px]">
              <QdTable
                title="Taboola"
                data={adsData?.campaigns?.get("taboola") || []}
                comparedData={comparedAdsData?.campaigns?.get("taboola") || []}
                loading={showLoadingState}
                progress={progress}
              />
            </div>
          </QdTableContainer>
        )}
        {(!!adsData?.campaigns?.get("outbrain")?.length || !finished) && (
          <QdTableContainer
            loading={showLoadingState}
            title="Outbrain"
            channel={"outbrain"}
            titleColor="bg-qdOutbrain"
          >
            <div className="-mt-[52px]">
              <QdTable
                title="Outbrain"
                data={adsData?.campaigns?.get("outbrain") || []}
                comparedData={comparedAdsData?.campaigns?.get("outbrain") || []}
                loading={showLoadingState}
                progress={progress}
              />
            </div>
          </QdTableContainer>
        )}
        {(!!adsData?.campaigns?.get("criteo")?.length || !finished) && (
          <QdTableContainer
            loading={showLoadingState}
            title="Criteo"
            channel={"criteo"}
            titleColor="bg-qdCriteo"
            beta
          >
            <div className="-mt-[52px]">
              <QdTable
                title="Criteo"
                data={adsData?.campaigns?.get("criteo") || []}
                comparedData={comparedAdsData?.campaigns?.get("criteo") || []}
                loading={showLoadingState}
                progress={progress}
              />
            </div>
          </QdTableContainer>
        )}

        {(!!adsData?.campaigns?.get("referred")?.length ||
          !!adsData?.campaigns?.get("organic")?.length ||
          !!adsData?.campaigns?.get("referral")?.length ||
          !finished) && (
          <QdTableContainer
            loading={showLoadingState}
            title="Organic"
            channel={"referred"}
            titleColor="bg-qdOrganic"
          >
            <>
              <QdTable
                title="Organic"
                data={
                  adsData?.campaigns?.get("referred") ||
                  adsData?.campaigns?.get("organic") ||
                  []
                }
                comparedData={
                  comparedAdsData?.campaigns?.get("referred") ||
                  comparedAdsData?.campaigns?.get("organic") ||
                  []
                }
                displayTitle={true}
                loading={showLoadingState}
                progress={progress}
              />
              {adsData?.campaigns?.get("referral") &&
              adsData?.campaigns?.get("referral")!.length > 0 ? (
                <QdTable
                  title="Referral"
                  displayTitle={true}
                  data={adsData?.campaigns?.get("referral") || []}
                  comparedData={
                    comparedAdsData?.campaigns?.get("referral") || []
                  }
                  loading={showLoadingState}
                  progress={progress}
                  disableFilter={true}
                />
              ) : null}
            </>
          </QdTableContainer>
        )}
        {(!!adsData?.campaigns?.get("direct")?.length || !finished) && (
          <QdTableContainer
            loading={showLoadingState}
            title="Direct"
            channel={"direct"}
            titleColor="bg-qdDirect"
            beta
          >
            <div className="">
              <QdTable
                title="Direct"
                data={adsData?.campaigns?.get("direct") || []}
                comparedData={comparedAdsData?.campaigns?.get("direct") || []}
                loading={showLoadingState}
                progress={progress}
                disableFilter
                type="direct"
              />
            </div>
          </QdTableContainer>
        )}
      </div>
    </div>
  );
}
