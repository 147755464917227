import { getRouterLocation } from "@lib/atomWithQuery";
import Link, { LinkProps } from "next/link";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import qs from "qs";
const pathnameRegex = /[^?]+/u;

// we don't want these values to persist over page changes via a Link
export const queryParamsToPurge = [
  "filters",
  "tabValue",
  "openExportModal",
  "tablesToExport",
  "viewToExport",
  "influencerFilter",
  "placementTypeFilter",
  "creative_comparison_1",
  "creative_comparison_2",
  "creativesRowSelection",
  "selected_creative_chart",
  "token",
];

const LinkWithPersistentQuery = ({
  children,
  href,
  as,
  ...props
}: LinkProps & {
  children: React.ReactNode;
  href: string;
  as?: string;
  className?: string;
}) => {
  const searchFromHref = useMemo(() => {
    const search = href.replace(pathnameRegex, "");
    return search;
  }, [href]);

  const searchFromAs = useMemo(() => {
    const search = as ? as.replace(pathnameRegex, "") : null;
    return search;
  }, [as]);
  const router = useRouter();

  const hrefWithoutSearch = useMemo(
    () =>
      searchFromHref ? href.replace(searchFromHref, "") : href ? href : "#",
    [href, searchFromHref]
  );
  const asWithoutSearch = useMemo(
    () => (as && searchFromAs ? as.replace(searchFromAs, "") : as ? as : "#"),
    [as, searchFromAs]
  );
  const routerLocation = useMemo(() => {
    return getRouterLocation(router);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady, router.asPath, router.query]);

  const totalSearch = useMemo(() => {
    // if we don't have a current search, return an empty string
    if (!searchFromHref && !routerLocation.search) {
      return { hrefSearch: "", asSearch: "" };
    }
    // get the query object from our current location without the ?
    const purgedRouterQuery = routerLocation.search
      ? qs.parse(routerLocation.search.replace("?", ""))
      : {};

    // remove query params we don't want to pass when changing pages
    queryParamsToPurge.forEach((el) => delete purgedRouterQuery[el]);
    // if we don't have a search added to the Link href, only return the
    // query we have created before as a search string
    if (!searchFromHref)
      return {
        hrefSearch:
          Object.keys(purgedRouterQuery).length > 0
            ? `?${qs.stringify(purgedRouterQuery)}`
            : "",
        asSearch: "",
      };

    // if we don't have a search string in our current location,
    // only return the search (if any) from the Link href
    if (!routerLocation.search)
      return {
        hrefSearch: searchFromHref,
        asSearch: searchFromAs ?? "",
      };

    // if we have a search in both the current location and the new Link href
    // we have to join these two searches to 1 single query object which then can
    // return as a unified search string
    const hrefQuery = qs.parse(searchFromHref.replace("?", ""));
    const asQuery = qs.parse(searchFromAs ?? searchFromHref);
    const completeHrefQuery = { ...purgedRouterQuery, ...hrefQuery };
    const completeAsQuery = { ...purgedRouterQuery, ...asQuery };
    const newHrefSearch =
      Object.keys(completeHrefQuery).length > 0
        ? `?${qs.stringify(completeHrefQuery)}`
        : "";
    const newAsSearch =
      Object.keys(completeAsQuery).length > 0
        ? `?${qs.stringify(completeAsQuery)}`
        : "";
    return { hrefSearch: newHrefSearch, asSearch: newAsSearch };
  }, [searchFromHref, routerLocation.search, searchFromAs]);

  // if we have an as in the Link props, preferably return this
  if (as && asWithoutSearch) {
    return (
      <Link
        href={`${hrefWithoutSearch}${totalSearch.hrefSearch}`}
        as={`${asWithoutSearch}${totalSearch.asSearch}`}
        {...props}
      >
        {children}
      </Link>
    );
  }
  return (
    <Link href={`${hrefWithoutSearch}${totalSearch.hrefSearch}`} {...props}>
      {children}
    </Link>
  );
};

export default LinkWithPersistentQuery;
