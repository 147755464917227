import {
  atomsInitializedAtom,
  endTimeAtom,
  previousEndTimeAtom,
  previousStartTimeAtom,
  startTimeAtom,
  timeRangeKeyAtom,
} from "atoms";
import { START_DATE_RANGE_SELECT_OPTIONS_FULL } from "constants/constants";
import { set } from "date-fns";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import {
  getPreviousDateRange,
  getStartDateRange,
  getTimerangeFromDates,
} from "services/DateRangeServices";

type Props = {
  maxDate?: Date;
  minDate?: Date;
  initialTimeRange?: {
    startDate: Date;
    endDate: Date;
    timerange: (typeof START_DATE_RANGE_SELECT_OPTIONS_FULL)[number]["value"];
  };
};

export const useInitialTimeRange = ({
  maxDate,
  initialTimeRange,
  minDate,
}: Props) => {
  const [endTime, setEndTime] = useAtom(endTimeAtom);
  const [startTime, setStartTime] = useAtom(startTimeAtom);
  const [previousEndTime, setPreviousEndTime] = useAtom(previousEndTimeAtom);
  const [previousStartTime, setPreviousStartTime] = useAtom(
    previousStartTimeAtom
  );
  const [timeRange, setTimeRange] = useAtom(timeRangeKeyAtom);
  const [dashboardInitialized, setDashboardInitialized] = useState(false);
  const [initialTimeRangeIsSet, setInitialTimeRangeIsSet] = useState(false);
  useEffect(() => {
    // when we change routes we want to wait the first render so that atoms can be initialized
    // and only then we can set the initial time range
    setDashboardInitialized(true);
  }, []);

  useEffect(() => {
    let newStartDate = dayjs(maxDate).isBefore(startTime, "seconds")
      ? dayjs(maxDate).startOf("day").toISOString()
      : startTime;
    let newEndDate = dayjs(maxDate).isBefore(endTime, "seconds")
      ? dayjs(maxDate).endOf("day").toISOString()
      : endTime;
    let newTimerange = getTimerangeFromDates({
      startDate: newStartDate,
      endDate: newEndDate,
    });
    // check if we have a maxDate and if the current timerange is after the maxDate
    // if so, set the timerange either to the initialTimeRange or to the maxDate
    if (dashboardInitialized && !initialTimeRangeIsSet) {
      if (maxDate) {
        const maxDateIsBeforeStartTime = dayjs(maxDate).isBefore(
          startTime,
          "seconds"
        );
        const maxDateIsBeforeEndTime = dayjs(maxDate).isBefore(
          endTime,
          "seconds"
        );
        const maxDateIsBeforeBoth =
          maxDateIsBeforeStartTime && maxDateIsBeforeEndTime;
        const maxDateIsBeforeEither =
          maxDateIsBeforeStartTime || maxDateIsBeforeEndTime;

        if (maxDateIsBeforeBoth) {
          if (initialTimeRange) {
            newStartDate = initialTimeRange.startDate.toISOString();
            newEndDate = initialTimeRange.endDate.toISOString();
            newTimerange = initialTimeRange.timerange as typeof newTimerange;

            setStartTime(initialTimeRange.startDate.toISOString());
            setEndTime(initialTimeRange.endDate.toISOString());
            setTimeRange(initialTimeRange.timerange);
          } else {
            setStartTime(newStartDate);
            setEndTime(newEndDate);
            setTimeRange(newTimerange);
          }
        } else if (maxDateIsBeforeEither) {
          if (maxDateIsBeforeStartTime) {
            setStartTime(newStartDate);
          }
          if (maxDateIsBeforeEndTime) {
            setEndTime(newEndDate);
          }
          setTimeRange(newTimerange);
        }
      }
      const previousDates = getPreviousDateRange("last_period", {
        startDate: newStartDate,
        endDate: newEndDate,
      });
      setPreviousStartTime(previousDates.startDate.toISOString());
      setPreviousEndTime(previousDates.endDate.toISOString());

      setInitialTimeRangeIsSet(true);
    }
    if (newTimerange !== "Custom") {
      const datesFromTimerange = getStartDateRange(newTimerange);
      let newTimerangeStartDate = null;
      let newTimerangeEndDate = null;
      if (
        !dayjs(datesFromTimerange.endDate).isSame(newEndDate, "day") &&
        !dayjs(maxDate).isBefore(datesFromTimerange.endDate, "seconds")
      ) {
        newTimerangeEndDate = datesFromTimerange.endDate.toISOString();
        setEndTime(newTimerangeEndDate);
      } else if (dayjs(maxDate).isBefore(newEndDate, "seconds")) {
        newTimerangeEndDate = dayjs(maxDate).endOf("day").toISOString();
        setEndTime(newTimerangeEndDate);
        setTimeRange("Custom");
      }
      if (
        !dayjs(datesFromTimerange.startDate).isSame(
          dayjs(newStartDate),
          "day"
        ) &&
        !dayjs(maxDate).isBefore(datesFromTimerange.startDate, "seconds")
      ) {
        newTimerangeStartDate = datesFromTimerange.startDate.toISOString();
        setStartTime(newTimerangeStartDate);
      } else if (dayjs(maxDate).isBefore(newStartDate, "seconds")) {
        setTimeRange("Custom");
        newTimerangeStartDate = dayjs(maxDate).startOf("day").toISOString();
        setStartTime(newTimerangeStartDate);
      }

      if (newTimerangeStartDate !== null || newTimerangeEndDate !== null) {
        const previousDates = getPreviousDateRange("last_period", {
          startDate: newStartDate,
          endDate: newEndDate,
        });
        setPreviousStartTime(previousDates.startDate.toISOString());
        setPreviousEndTime(previousDates.endDate.toISOString());
      }
    }
  }, [
    dashboardInitialized,
    endTime,
    initialTimeRange,
    initialTimeRangeIsSet,
    maxDate,
    setEndTime,
    setPreviousEndTime,
    setPreviousStartTime,
    setStartTime,
    setTimeRange,
    startTime,
  ]);

  return {
    initialTimeRangeIsSet,
    setInitialTimeRangeIsSet,
  };
};
