import {
  InformationCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { getSelectColors, getSizes } from "@ui/select/styles";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui";
import { newVsReturningAtom } from "atoms";
import { NVR_OPTIONS } from "constants/constants";
import { useAtom } from "jotai";
import React, { useMemo } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
} from "@components/ui/select";

const NvrDropdown = () => {
  const [newVsReturning, setNewVsReturning] = useAtom(newVsReturningAtom);

  const { height } = useMemo(() => getSizes("medium"), []);
  const colors = useMemo(() => getSelectColors("lite"), []);

  return (
    <Select
      value={newVsReturning}
      onValueChange={(val: typeof newVsReturning) => setNewVsReturning(val)}
    >
      <SelectTrigger className="bg-gray-200 dark:bg-gray-700">
        <span className="truncate font-semibold mr-2 inline-flex items-center">
          <UserGroupIcon className="h-4 w-4 inline-block mr-2" />{" "}
          {(
            NVR_OPTIONS.find((el) => el.value === newVsReturning)?.label ??
            "All Users"
          ).replace("Users", "")}
        </span>
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Customer Segment</SelectLabel>
          {NVR_OPTIONS.map((option) => (
            <React.Fragment key={option.value}>
              {option.value === "default" ? (
                <>
                  <SelectItem value={option.value}>
                    <div className="flex items-center">
                      All Users
                      <Tooltip>
                        <TooltipTrigger className="ml-2">
                          <InformationCircleIcon className="h-5 w-5" />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p className="w-96">
                            Faster loading. Doesn&apos;t include CAC and
                            differentiation between new vs returning users
                          </p>
                        </TooltipContent>
                      </Tooltip>
                    </div>
                  </SelectItem>
                  <SelectSeparator />
                </>
              ) : (
                <SelectItem value={option.value}>{option.label}</SelectItem>
              )}
            </React.Fragment>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default NvrDropdown;
