import { XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";
import { Fragment, useEffect, useMemo, useRef } from "react";
import { NormalSizes } from "@lib/util-types";

export interface ModalProps {
  title?: string | React.ReactNode;
  footer?: React.ReactNode;
  disableBackgroundClick?: boolean;
  onClose: () => void;
  onEnter?: () => void | null;
  open?: boolean;
  className?: string;
  children: React.ReactNode;
  type?: "default" | "lite";
  width?: NormalSizes;
}

export const Modal: React.FC<ModalProps> = ({
  title,
  onClose,
  footer,
  open = false,
  disableBackgroundClick = false,
  onEnter,
  className = "",
  type = "default",
  children,
  width = "medium",
}) => {
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (ref.current) {
      disableBodyScroll(ref.current, { reserveScrollBarGap: true });
    }
    return () => {
      if (ref && ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        enableBodyScroll(ref.current);
      }
      clearAllBodyScrollLocks();
    };
  }, []);

  const widthClassNames = useMemo(() => {
    if (width === "small") {
      return "max-w-xl";
    } else if (width === "medium") {
      return "max-w-3xl";
    } else if (width === "large") {
      return "max-w-5xl";
    }
  }, [width]);

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-popover overflow-y-auto"
          onClose={!disableBackgroundClick ? () => onClose() : () => null}
        >
          <div className="min-h-screen text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div>
                <Dialog.Overlay className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
              </div>
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={`inline-block relative w-full ${widthClassNames} my-8 rounded-lg border border-none dark:border-black overflow-hidden text-left align-middle transition-all transform ${
                  type === "lite"
                    ? "bg-gray-200 dark:bg-gray-700"
                    : "bg-gray-100 dark:bg-gray-800"
                } text-foreground shadow-lg`}
                style={width ? { width } : undefined}
              >
                <button
                  onClick={() => onClose()}
                  aria-label="Close panel"
                  className={
                    "hover:text-gray-200 transition ease-in-out duration-150 focus:outline-none absolute right-0 top-0 m-2 z-10"
                  }
                >
                  <XMarkIcon className="h-5 w-5" />
                </button>
                {title && (
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 px-6 py-4 bg-gray-200 dark:bg-gray-700 break-words"
                  >
                    {title}
                  </Dialog.Title>
                )}
                <div
                  className={`relative p-6 ${
                    type === "lite" ? "pt-0" : ""
                  } max-h-[70vh] overflow-x-auto`}
                >
                  {children}
                </div>
                {footer && <div className="relative">{footer}</div>}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Modal;
