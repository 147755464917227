import {
  Organisation,
  OrganisationAPIResponse,
  OrganisationsAPIResponse,
} from "./types";

export const transformToOrganisation = (
  organisation: OrganisationAPIResponse
) => {
  return {
    id: organisation.id,
    name: organisation.name,
    accounts: organisation.accounts,
    customerSites: organisation.customer_sites,
    featureFlags: organisation.feature_flags,
  };
};

export const transformToOrganisations = (data: OrganisationsAPIResponse) => {
  const organisations: Array<Organisation> = data.map((organisation) =>
    transformToOrganisation(organisation)
  );

  return organisations;
};
