import { transformToCooperationLinks } from "../useLinks";
import {
  Cooperation,
  CooperationAPIResponse,
  CooperationsAPIResponse,
} from "./types";

export const transformToCooperation = (
  cooperation: CooperationAPIResponse,
  selectedCsids?: string[]
) => {
  const coop: Cooperation = {
    id: cooperation.id,
    name: cooperation.name ?? `${cooperation.influencer_id ?? ""} cooperation`,
    influencerId: cooperation.influencer_id,
    commission: cooperation.commission,
    labels: cooperation.labels ?? [],
    csids: null,
    currency: cooperation.currency,
    placementType: cooperation.placement_type,
    source: cooperation.source,
    variableCommission: cooperation.variable_commission,
    variableCommissionType: cooperation.variable_commission_type,
    scheduledFor: cooperation.scheduled_for,
    links: transformToCooperationLinks(cooperation.links),
    discountCodes: transformToCooperationLinks(
      cooperation.links?.filter((el) => !!el.discount_code) ?? []
    ),
  };
  const csids: string[] = [];
  for (const link of coop.links) {
    if (link.csid && !csids.includes(link.csid)) {
      csids.push(link.csid);
    } else if (!link.csid) {
      // if a link doesn't belong to a store, it automatically means that it belongs to all stores
      // in that case, we want to add all selected csids to the list (meaning the cooperation belongs to all stores)
      selectedCsids?.forEach((el) =>
        !csids.includes(el) ? csids.push(el) : null
      );
    }
  }
  if (csids.length > 0) {
    coop.csids = csids;
  }

  return coop;
};

export const transformToCooperations = (
  data: CooperationsAPIResponse,
  csids?: string[]
) => {
  let cooperations: Array<Cooperation> = data.map((cooperation) =>
    transformToCooperation(cooperation, csids)
  );

  if (csids?.length) {
    cooperations = cooperations.filter((el) => {
      if (!el.csids || el.csids.length === 0) {
        return true;
      }
      let isIncludedInCsids = false;
      for (const csid of el.csids) {
        if (csids.includes(csid)) isIncludedInCsids = true;
      }
      return isIncludedInCsids;
    });
  }
  return cooperations;
};
